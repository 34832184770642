import { render, staticRenderFns } from "./UserRegLeadAddV1.vue?vue&type=template&id=bbbc9ac6&scoped=true"
import script from "./UserRegLeadAddV1.vue?vue&type=script&lang=js"
export * from "./UserRegLeadAddV1.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./UserRegLeadAddV1.vue?vue&type=style&index=1&id=bbbc9ac6&prod&lang=scss&scoped=true"
import style2 from "./UserRegLeadAddV1.vue?vue&type=style&index=2&id=bbbc9ac6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbbc9ac6",
  null
  
)

export default component.exports